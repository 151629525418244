<template>
	<v-footer padless color="grey darken-4">
		<v-col class="text-center white--text" cols="12">
			{{ new Date().getFullYear() }} —
			<strong>PT-Mahapurna Mandiri Utama</strong>
		</v-col>
	</v-footer>
</template>

<script>
export default {
	data() {
		return {
			name: 'Footer',
		};
	},
};
</script>
