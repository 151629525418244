<template>
  <v-app>
    <v-container class="fill-height">
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters class="d-flex justify-center">
            <v-img src="../assets/maintenance.svg" max-width="500"></v-img>
          </v-row>
          <br>
          <v-row no-gutters class="d-flex justify-center text-center">
            <v-col>
              <h1 class="blue-grey--text">Website Sedang Dalam Perbaikan</h1>
              <br />
              <h2 class="blue-grey--text font-weight-light">Silahkan Kunjungi Kembali Beberapa Saat Kemudian</h2>
            </v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-row class="fill-height d-flex align-center" no-gutters>
      <v-col class="d-flex justify-center">
        <v-img src="../assets/maintenance.svg" max-width="500"></v-img>
      </v-col>
      <v-col>
        <v-card outlined max-width="700">
          <v-card-text>
            <h1>Website Sedang Dalam Perbaikan</h1>
            <br />
            <h2>Silahkan Kunjungi Kembali Beberapa Saat Kemudian</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <Footer />
  </v-app>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  data() {
    return {};
  },
  components: { Footer },
};
</script>